@import '../../partials/general';
@import '../../partials/desktop/general';
@import '../../partials/desktop/header';
@import '../../partials/desktop/footer';
@import '../../widgets/breadcrumbs/general';
@import '../../widgets/social/general';
@import '../../partials/general/actions';
@import '../../partials/general/actions_filter';
@import '../common/shopping_buttons';
@import 'general';

.showcase-container {
  .showcase{
    .products-showcase{
      &.showcase-4x{
        .products-list {
          padding-right: 0;
          margin-left: 0;
        }
        .slick-list{
          margin-left: -15px;
        }
      }
      &.showcase-image-2{
        .top-details{
          .picture{
            height: 100%;
          }
        }
      }
    }
  }
}

.featured-products-slider {
  .featured-products-slider-arrow {
    &.a-left{
      margin-left: -570px;
    }
    &.a-right{
      margin-left: 570px;
    }
  }
  .slick-slider{
    .arrow {
      &.left {
        left: calc(50% - 540px);
      }
      &.right {
        right: calc(50% - 540px);
      }
    }
  }
  .featured-products-wrapper {
      .featured-products {
        height: 350px;
        .featured-product {
          .picture {
            margin-left: 65px;
          }
        }
      }
  }
} 

.showcase-container .featured-products-slider {
  padding-top: 60px;
  padding-bottom: 60px;
  .featured-products-wrapper {
    height: 300px;
    .slider-banner-bg-img {
      /*background-color: $showcase-slider-bg-color;*/
    }
    .featured-products {
      height: 300px;
      .featured-product {
        .slider-banner{
          min-height: 300px;
        }
        .picture-container{
          margin-left: -30px;
        }
        .details {
          height: 300px;
          .details-container {
            vertical-align: top;
            padding-top: 40px;
            height: 300px;
            .title {
              font-size: 48px;
              line-height: 52px;
              padding-bottom: 30px;
              /*color: $showcase-slider-title-color;*/
            }
            .subtitle {
              font-size: 25px;
              line-height: 28px;
              padding-bottom: 20px;
              /*color: $showcase-slider-subtitle-color;*/
            }
          }
        }
      }
    }
  }
}

