@import 'header-search';
@import 'header-shopping-cart';

main{
    padding-top: 50px;
}

body {
    &.headerless > main{
        padding-top: 0;
    }
    & > header{
        position: absolute;
        left: 0;
        right: 0;
        top:0;
        z-index: 999;
        height: 50px;
        @include context("body", "body.cart-open"){
            overflow: auto;
            height: 100%;
        }

        &.fixed {
            position: fixed;
        }

        .overlay{
            display: none;
            background-color: rgba(0, 0, 0, 0.75);
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;

            @include context("body", "body.cart-open"){
                display: block;
            }
        }

        nav {
            background-color: #fff;
            height: 50px;
            position: relative;
            .brand{
                margin-top: 5px;
                margin-bottom: 5px;
            }

            img.brand-logo {
                /*width: 84px;*/
                height: 40px;
            }

            .links-container {
                margin: 0px 0px 0px 30px;
                border-bottom: 1px solid #fe0000;
                height: 50px;
            }

            ul.links{
                display: inline-block;
                font-size:15px;
                list-style:none;
                text-align: center;
                width: auto;
                a:active, a:focus{
                    text-decoration: none;
                    color: inherit;
                    outline: none;
                }
                .lvl-0{
                    display: inline-block;
                    padding-right:15px;
                    cursor: default;
                    &.hover>.option>.name, &.hover-child>.option>.name{
                        color: #fe0000;
                    }
                    &.last{
                        margin-right:0;
                    }

                    &>.option>.name{
                        color: #000;
                        padding: 15px 0;
                        text-decoration: none;
                        font-weight: 600;
                        line-height: 19px;
                        &.active{
                            color: #fe0000;
                        }
                        a {
                            padding: 15px 0;
                            &.active{
                                color: #fe0000;
                            }
                        }
                    }
                    a{
                        cursor: pointer;
                    }
                }

                .menu {
                    background-color: #262626;
                    display: none;
                    left: 0;
                    padding: 25px 0 35px 0;
                    position: absolute;
                    right: 0;
                    text-align: left;
                    top: 50px;
                    z-index: 9999;

                    a {
                        color: #9d9d9d;
                        &:hover{
                            text-decoration: underline;
                        }
                        &.active{
                            color: #fe0000;
                        }
                    }

                    &.list {

                        &>.vo-container{
                            position: relative;
                        }
                        .col {
                            box-sizing: content-box;
                            display: inline-block;
                            float: left;
                            vertical-align: top;
                            padding: 0 15px;

                            &.col-1 {
                                padding-left: 0;
                            }

                            &.separated {
                                li.separator{
                                    position: absolute;
                                    top: 28px;
                                    bottom: -10px;
                                    border: none;
                                    padding: 0;
                                    background-color: #9d9d9d;
                                    width: 1px;
                                    margin-left: -15px;
                                }

                            }

                            &.last {
                                padding-right: 0;
                            }

                            &.col-size-0d5 {
                                width: 70px;
                            }

                            &.col-size-1 {
                                width: 160px;
                            }

                            &.col-size-1d5 {
                                width: 250px;
                            }

                            &.col-size-2 {
                                width: 350px;
                            }
                        }

                        .cta.single-field{
                            position: relative;
                            width: 295px;
                            height: 50px;
                            margin-top: 5px;
                            input[type=text] {
                                padding: 17px 60px 15px 30px;
                                border: none;
                                font-size: 11px;
                                color: #a4a4a4;
                                font-weight: 600;
                                left: 0;
                                bottom: 0;
                                top: 0;
                                right: 0;
                                position: absolute;
                                width: 100%;
                                height:50px;
                                border-radius: 0;
                                @include placeholder {
                                    text-align:center;
                                    color: #a4a4a4;
                                    text-transform: uppercase;
                                }
                            }
                            label{
                                position: absolute;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                height: 100%;
                                color: #fff;
                                width: 30px;
                                background-color: #fda100;
                                border: none;
                                span{
                                    position: absolute;
                                    right: -10px;
                                    top: 0;
                                    font-size: 50px;
                                    &:before {
                                        content: "\6c";
                                    }
                                }
                            }
                        }
                    }

                    &.author {

                        .col-1{
                            display: inline-block;
                            margin-right: 15px;
                            vertical-align: top;
                            width: 255px;
                        }
                        .col-2{
                            display: inline-block;
                            margin-right: 30px;
                            vertical-align: top;
                            width: 270px;
                        }
                        .col-3{
                            @extend %link-no-effects;
                            display: inline-block;
                            margin-right: 30px;
                            vertical-align: bottom;
                            width: 160px;
                        }

                        h4{
                            @extend .text-regular;
                            color: #fe0000;
                            text-transform: uppercase;
                        }

                        h5{
                            @extend .text-large;
                            color: #fe0000;
                            margin-bottom: 15px;
                            margin-top: 30px;
                        }

                        .content{
                            @extend .text-regular;
                            color: #9d9d9d;
                            margin-bottom: 20px;
                        }

                        .more {
                            @extend .text-regular;
                            text-decoration: underline;
                            color: #9d9d9d;
                            text-transform: none;
                        }

                        .author-name-link{
                            text-decoration: none;
                        }

                        .picture {
                            height: 300px;
                            margin-top: 15px;
                            width: 255px;
                            background-size: cover;
                        }

                        a.cta{
                            @extend %link-no-effects;
                            .button{
                                @include button-type-b(#fe0000);
                                padding: 12px 5px;
                                font-weight: 600;
                                position: relative;
                                &>.icon-C{
                                    font-size: 50px;
                                    position: absolute;
                                    top: -4px;
                                }
                            }
                        }
                    }

                    &.layout-d{
                        .col {
                            box-sizing: content-box;
                            display: inline-block;
                            padding: 0 0 0 30px;
                            vertical-align: top;
                            width: 350px;
                            float: left;

                            &.col-1{
                                padding-left: 0;
                            }
                            &.col-2,&.col-3 {
                                width: 160px;
                            }
                        }
                    }

                    .title, .subtitle, .subtitle a {
                        @extend .text-regular;
                        color: #fff;
                        line-height: 28px;
                    }

                    .title{
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-bottom: 7px;
                        letter-spacing: -0.1px;
                    }

                    .subtitle{
                        margin-bottom: 2px;
                        &.first{
                            margin-top: 35px;
                        }
                    }

                    .item{
                        @extend .text-regular;
                        color: #9d9d9d;
                        line-height: 28px;
                        .orange{
                            color: #fda100;
                        }

                        &.first{
                            margin-top: 35px;
                        }

                        &.higher-margin{
                            margin-bottom: 10px;
                        }

                        &>.option{
                            font-weight: 400;
                            text-transform: none;
                            font-size: 14px;
                            line-height: 28px;

                            .icon-U{
                                display: none;
                            }
                        }
                    }

                }
                li.hover:not(.menu) + .menu, li.hover-child:not(.menu) + .menu {
                    display: block;
                }
            }

            ul.navbar-misc {
                display: inline-block;
                list-style: none;
                height: 50px;
                overflow: hidden;
                line-height: 50px;
                padding: 7px 0;
                &>li {
                    margin-top: -6px;
                    float: left;
                    color: #959595;
                    font-size: 50px;
                    padding: 0;
                    cursor: default;
                    position: relative;
                    &:not(.disabled) {
                        cursor:pointer;
                        &:hover, &.open {
                            color: #fe0000;
                        }
                    }

                    &>a {
                        text-decoration: none;
                    }

                    &.empty-shopping-cart .total-cart-items{
                        display:none;
                    }

                    &:not(.menu):hover + .menu, .menu:hover {
                        display: block;
                    }

                    &:hover .total-cart-items {
                        background: #959595;
                        color: #fff;
                        border-color: #959595;
                    }

                    & > .total-cart-items{
                        font-size: 12px;
                        font-weight: 700;
                        text-align: center;
                        color: #ffffff;
                        border-radius: 21px;
                        border-style: solid;
                        border-width: 1px;						
                        border-color: #fe0000;						
                        background: #fe0000;
                        width: 21px;
                        height: 21px;
                        line-height: 19px;
                        position: absolute;
                        right: calc(50% - 21px);
                        top: 5px;
                    }
                }

            }
        }
    }
}
