@import 'general';

.modal.modal-vo-default,
.modal {
  .modal-dialog{
    margin-top: 300px;
  }

  .modal-close {
    right: -255px;
    top: -226px;
  }

}
#reseller-modal .modal-dialog {
  margin-top: 0;
}