footer{  
    .layer-1{
        padding-top: 40px;
        height:135px;
        background-color: #262626;
        color: #9d9d9d;
        text-align: left;

        h4 {
            font-size: 14px;
        }

        .title-container{
            margin-bottom: 15px;
        }

        li {
            display: inline-block;
            a, span{
                color:#9d9d9d;
                font-size:12px;
                text-decoration: none;
            }
        }        
    }

    .layer-2{
        background-color: #000;
        padding: 45px 0 35px 0;
        font-size: 12px;
        line-height: 18px;
        color: #9d9d9d;

        h6{
            color: #fff;
        }

        hr{
            width: 100%;
            height: 1px;
            margin: 25px auto;
            background-color: rgba(157,157,157,0.25);
        }

        .social{
            font-size: 50px;
            a{
                text-decoration: none;
                color: #9d9d9d;
            }
            span:first-child {
                margin-left: -4px;
            }
        }

        .menu {

            &.col-8-desktop{
                list-style: none;  
                .col { 
                    box-sizing: content-box;
                    display: inline-block;
                    float: left;
                    vertical-align: top;
                    /*width: 12.5%;*/

                    &.col-size-3{
                        width: 240px;
                        &.col-1,
                        &.col-8
                        {
                            width: 255px;
                        }
                        padding: 0 15px 0 15px;
                    }
                    
                    &.col-1{
                        padding-left: 0; 
                    }

                    &.col-8{
                        padding-right: 0;
                    }
                }
            }

            .vo-container{
                width: 100%;
                padding: 0;
            }

            a {                
                text-decoration: none;
                color: #9d9d9d;
                &:hover{
                    text-decoration: underline;
                }
                &.active{
                    color: #fe0000;
                }
            }

            .title {
                color: #fff;
                margin-bottom: 5px;
                margin-top: 20px;
                font-weight: 700;
                &.first{
                    margin-top: 0;
                }
                a {
                    color: #fff;
                    &.active{
                        color: #fe0000;
                    }
                }
            }

            .option {
                padding-right: 6px;
                .arrow, .icon-U{
                    display: none;
                }
            }
        }

        h6{
            margin: 0 0 15px 0;
        }

        .subscribe-container{
            .field{
                position: relative;
                display: inline-block;
            }
            button {
                border: none;
                outline: none;
                background: none;
                font-size: 50px;
                position: absolute;
                right: -15px;
                top: -4px;
            }
            input{
                background-color: transparent;
                color: #9d9d9d;
                border: solid #9d9d9d 1px;
                padding: 10px;
                width: 160px;
                padding-right: 35px;
                font-size: 11px;
                @include placeholder {
                    text-transform: uppercase;
                    text-align: center;
                }
            }
        }


        .section-1 {
            &>div:first-child {
                padding-left: 0;
            }
        }
    }
}
