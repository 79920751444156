.widget-highlights{
    padding: 40px 0;
    font-size: 25px;
    text-align: left;
    color: #000;

    .highlights-container {
        margin-top: 15px;

        &.slick-slider {
            overflow: hidden;
        }

        .slick-list {
            margin: 0 -15px;
        }
        &.vo-carousel-load-fix{
            height: 230px;
            overflow: hidden;
        }

        .vo-carousel-item {
            &.slick-slide {
                margin: 0 15px;
            }
            figcaption h3 {
                margin-top:15px;
                font-size: 14px;
                line-height: 18px;
                text-align: left;
                text-transform: uppercase;
                color: #000;      
                font-weight: 700;
            }
            .description {
                font-size: 14px;
                line-height: 18px;
                text-align: left;
                color: #000;
                p{
                    margin:0;
                }
            }
        }
    }
}
