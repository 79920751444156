@import '../../widgets/reseller/desktop';

// @extends
.text-regular {
    font-size: 14px;
    line-height: 18px;
}

.text-large {
    font-size: 25px;
    line-height: 28px;
}

.vo-container, .vo-container-desktop{
    width: 1170px;
    margin:0 auto;
    padding: 0 30px;
    height: 100%;
}

.background-container{
    position: absolute;
    top: 0;
    width: 1110px;
    left: 50%;
    bottom: 0;
    margin-left: -555px;
}

.hidden-desktop{
    display: none!important;
}

.pull-left-desktop{
    float: left;
}

.pull-right-desktop{
    float: right;
}

.pdg-15-l-desktop{
    padding-left: 15px;
}

.pdg-15-r-desktop{
    padding-right: 15px;
}

.pdg-30-t-desktop{
    padding-top: 30px;
}

#back-to-top{
    z-index: 999;
    position: fixed;
    background-color: #262626;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #9d9d9d;
    padding-top: 12px;
    right: 0;
    height: 40px;
    width: 125px;
    bottom: 0;
    display:none;
    &.show{
        display: block;
    }
    span{
        position: absolute;
        right: 5px;
        font-size: 50px;
        top: -2px;
    }
}

.select2-dropdown--above{
    top: 3px;
}
.select2-dropdown--below{
    top: 3px;
}

@mixin arrow{
    cursor: pointer;
    width: 50px;
    height: 90px;
    top: 50%;
    margin-top: -45px;
    border-radius: 3px;
    background-color: #000;
    color: #fff;
    opacity: 0.25;
    font-size: 50px;
    position: absolute;
    padding: 20px 0;
    &.left{
        left:0;
    }
    &.right{
        right:0;
    }
    span {
        position: absolute;
        font-style: normal;
        color: #fff;
        left: 0;
    }
}

@mixin carousel-selector($color) {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: $color;
    display: inline-block;
    margin: 0 15px;
    cursor: pointer;
}

@mixin button-type-a($color) {
    width:160px;
    height:40px;
    background-color: transparent;
    color: $color;
    border: solid $color 1px;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

@mixin button-type-b($color) {
    width:160px;
    height:40px;
    background-color: $color;
    color: #fff;
    border: none;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

@mixin button-type-checkout($color) {
    background-color: $color;
    color: #fff;
    border: none;
    text-align: center;
    cursor: pointer;
    height: 40px;
}

.slick-slider{
    .slick-slide {
        a:focus {
            outline: none;
        }
        img {
            width: 100%;
        }
    }
    .arrow{
        @include arrow;
    }
    .slick-dots {
        position: absolute;
        text-align: center;
        width: 100%;
        bottom: 10px;
        &>li{
            @include carousel-selector(#fff);
            &.slick-active{
                @include carousel-selector(#fe0000);
            }
            button {
                display: none;
            }
        }
    }
}

@import '../../widgets/modal/desktop';