//General
@import '../../partials/general';
//Partials
@import '../../partials/desktop/general';
@import '../../partials/desktop/header';
@import '../../partials/desktop/footer';
//Widgets
@import '../../widgets/highlights/desktop';
//Store
@import '../../store/showcase/desktop';

@mixin background-cover($height, $background){
    width: 100%;
    height: $height;
    position:relative;
    background: url($background) center center;
    background-size: cover;
}

body{
    min-width: 1170px;
}

main{
    background-color: #fff;
}

#rotator-cover-large {
    overflow: hidden;
    width: 100%;
    position: relative;

    .slider{
        background-color: #262626;
        position: static;
        &.with-news {
            min-height: 110px;
        }
        &.vo-carousel-load-fix{
            height: 600px;
            opacity: 1;
        }
    }
    .slick-slide a:focus {
        outline: none;
    }
    .slick-slide img {
        width: 100%;
    }

    .top-slider{
        .arrow{
            position: absolute;
            bottom: 320px;
            color: #fff;
            font-size: 50px;
            background: transparent;
            opacity: 1;
            top: 50%;
            margin-top: -100px;
            width: auto;
            padding: 0;
            &.left{
                left: 50%;
                margin-left: -555px;
            }
            &.right{
                right: 50%;
                margin-right: -555px;
            }
        }
    }
    .slick-dots {
        position: absolute;
        bottom: 120px;
        left: 0;
        right: 0;
        text-align: center;
        width: 100%;
        &>li{
            @include carousel-selector(#fff);
            &.slick-active{
                @include carousel-selector(#fe0000);
            }
            &>button{
                display: none;             
            }
        }
    }
}

.news {
    background-color: rgba(255,255,255,0.65);
    left:0;
    right:0;
    height:110px;
    bottom: 0;
    position:absolute;
    padding: 15px 30px;
    line-height: 18px;
    text-align: left;
    color: #000;
    font-size: 14px;
    z-index: 1;
    width: 1110px;
    margin: 0 auto;

    a, a:hover, a:active{
        text-decoration: none;
        color: inherit;
    }

    .arrow{
        @include arrow;
        top: -4px;
        margin-top: 0px;
        &.left{
            margin-left: -15px;
        }

        &.right{
            margin-right: -15px;
        }
    }

    .frame{
        position: relative;

        .slick-slide.slick-active{
            &:nth-child(n+2):before {
                content: '';
                border-left: solid black 1px;
                position: absolute;
                min-height: 70px;
                left: 0px;
            }
            &.slick-current:nth-child(n+2):before {
                content: '';
                border-left: 0;
            }
        }

        .news-item {
            padding: 0 10px;
            position: relative;
            a {
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 16px;
            }
        }
    }

    .date{
        font-weight: 600;
    }
}

.slider-t2{
    padding: 15px 0;
    position:relative;
    .carousel-slider {
        &.vo-carousel-load-fix{
            height: 300px;
            overflow: hidden;
        }

        .rotator{
            position: relative;
        }

        .description {
            background-color: #000;
            margin: 0 80px;
            padding: 54px 0;
            width: 285px;
            height: 100%;
            opacity: 0.85;
            h2 {
                font-size: 38px;
                line-height: 42px;
                letter-spacing: 0.1em;
                text-align: center;
                color: #fff;
                font-weight: 700;
                margin: 0 0 20px 0;
            }
            h3 {
                font-size: 25px;
                line-height: 28px;
                letter-spacing: 0.1em;
                text-align: center;
                text-transform: uppercase;
                color: #fff;
                font-weight: 300;
                margin: 0;
                padding: 0 20px;
            }
        }
        & .arrow {
            width: 50px;
            height: 90px;
            border-radius: 3px;
            color: #fff;
            position: absolute;
            top: 105px;
            font-size: 50px;
            margin: auto 15px;
            &.left{
                left:0;
            }
            &.right{
                right:0;
            }
            span{
                position: absolute;
                top: 20px;
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 20px;
        text-align: center;
        left:0;
        right:0;
        &>li{
            @include carousel-selector(#fff);
            &.slick-active{
                @include carousel-selector(#fe0000);
            }
            &>button{
                display: none;
            }
        }
    }
}

.espaco-lingua-portuguesa{
    padding: 30px 0 35px 0;
    @include background-cover(300px, "../../../images/lp_bg.png");
    h2 {
        margin-bottom: 15px;
        text-align: center;
        font-size: 25px;
        text-transform: uppercase;
        color: #fff;
    }
    h3 {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #9d9d9d;
        width: 270px;
        margin: 0 auto 25px auto;
    }

    .container{
        position: relative;
        padding: 25px 0;
        &>div{
            width: 50%;
            display: inline-block;
            float: left;
        }
    }

    h4{
        font-weight: bold;
        font-size: 25px;
        text-align: left;
        color: #fff;
        float: left;
        margin-top: 10px;
    }

    .left{
        h4{
            width: 270px;
        }

        a{
            color: #fe0000;
        }
    }

    .right{
        h4{
            width: 200px;
        }

        a{
            color: #fda100;
        }
    }

    .cta{
        display: inline-block;
    }


    form.single-field{
        position:relative;

        input[type=text] {
            display: inline-block;
            width: 325px;
            font-size: 14px;
            height: 50px;
            line-height: 50px;
            padding: 0px 60px 0px 30px;
            text-align: left;
            border: none;
            color: #000;
            border-radius: 0;
            @include placeholder {
                text-align:center;
                color: #a4a4a4;
                font-size: 11px;
                text-transform: uppercase;
                padding-top: 2px;
            }
        }
        button{
            outline: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 30px;
            background-color: #fda100;
            border: none;
            color: #fff;
            span{
                position: absolute;
                right: -10px;
                top: 0;
                font-size: 50px;
            }
        }
    }

    a.single-button{
        outline: none;
        display: inline-block;
        width: 255px;
        font-size: 11px;
        min-height: 50px;
        color: #fff;
        padding: 17px 50px;
        text-align: center;
        text-transform: uppercase;
        background-color:#fe0000;
        border:none;
        position:relative;
        span{
            position:absolute;
            right: -10px;
            top: 0;
            font-size: 50px;
        }
    }

    a{
        font-size: 14px;
    }

    hr {
        width: 1px;
        left: 50%;
        background-color: #fff;
        height: 120px;
        position: absolute;
        top: -20px;
    }

}

#descricao{
    .description-container{
        text-align: center;
        margin-top: 50px;
    }
    .button {
        @include button-type-a(#000);
        margin: 0 auto;        
        padding: 11px 0px;
        text-decoration: none;
    }
}

#destaques-1{
    a, a:hover, a:active{
        text-decoration: none;
        color: inherit;
    }
    .highlights-container{
        img {
            max-height: 172px;
        }
    }
}

.destaques-2{
    height: 335px;
    background-color:#828282;
    padding: 40px 0;
    overflow: hidden;

    .arrow{
        margin-right: 15px;
        margin-left: 15px;
    }

    .items-container{
        width: 830px;
        margin-left: 280px;
    }

    .sep{
        height: 260px;
        width:1px;
        background-color: #fff;
        position:absolute;
        left:25%;
    }
    .top, .bottom{
        height: 122px;
        position:absolute;
    }
    .top{
        top: 7px;
        width: 100%;
    }
    .bottom{
        top: 144px;
        width: 100%;
        padding-right: 40px;
    }

    .item, .main-item{
        position: relative;
        height: 260px;
    }

    .main-item{
        width: 25%;
        float: left;
    }

    h2{
        position: absolute;
        bottom: 0;
        left:0;
        right:0;
        font-size: 25px;
        line-height: 28px;
        text-transform: uppercase;
        color: #fff;
        padding-right: 80px;
    }

    h3{
        font-size: 25px;
        line-height: 28px;
        color: #fff;
        margin: 0 0 15px 0;
    }

    .img-container img {
        width: 67px;
        height: 67px;
        margin: 0 auto;
    }

    .description{
        margin-top:15px;
    }

    p{
        font-size: 14px;
        line-height: 18px;
        color: #fff;
    }
    .button {
        @include button-type-a(#fff);
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -80px;
        padding: 11px 0px;
        text-decoration: none;
    }

    .items-container{
        .item{
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

.destaques-3{

    ul{
        &>li {
            display: inline-block;
            position:relative;
            padding: 40px 0;
            width: 100%;
            &.separator {
                border-bottom: solid #000 1px;
            }

            .col-xs-6{
                padding: 0 15px;
            }

            section .img-container{
                text-align: right;
                .circle-img {
                    width: 250px;
                    height: 250px;
                    border-radius: 50%;
                }
            }
        }
    }

    .description {
        margin-bottom: 30px;
    }

    h2 {
        font-size: 25px;
        line-height: 28px;
    }

    p{
        font-size: 14px;
        line-height: 18px;
    }

    a {
        text-decoration: none;
        .button{
            @include button-type-a(#000);
            padding: 11px 0;
        }
    }
}

.destaques-4 {
    height:135px;
    padding: 38px 0;
    background-color: #d9d9d9;
    .img-container {
        float: left;
        img {
            min-width: 60px;
            width: auto;
            height:auto;
            vertical-align: top;
            margin-right: 15px;
        }
    }

    p, h2{
        font-size: 14px;
        line-height: 18px;
    }

    .description {
        margin: 5px 0;
        width:175px;
        display: inline-block;
        vertical-align: top;
    }

    a, a:hover, a:active{
        text-decoration: none;
        color: inherit;
    }
}

.destaques-5{
    height: 335px;
    background-color:#828282;
    padding: 40px 0;
    margin: 25px 0 40px 0;    
    overflow: hidden;

    .highlights-container {
        width: 100%;
        height: 100%;
    }   

    .vo-carousel-item {
        position: relative;
        height: 260px;
        &.slick-slide {
            width: 277px;            
            padding: 0 15px;            
        }
    }
            
    h3{
        font-size: 25px;
        line-height: 28px;
        color: #fff;
        margin: 0 0 15px 0;
    }

    .img-container img {
        width: 67px;
        height: 67px;
        margin: 0 auto;
    }

    .description{
        margin-top:15px;
    }

    p{
        font-size: 14px;
        line-height: 18px;
        color: #fff;
    }
    .button {
        @include button-type-a(#fff);
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -80px;
        padding: 11px 0px;
        text-decoration: none;
    }

}

/*
// Client asked for specific resolutions show top banner rotator smaller enough to make second rotator visible
//1280x800
@media all and (min-width: 1200px) and (max-width: 1280px) {
    #rotator-cover-large .slider{
        max-height: 425px;
        overflow: hidden;
    }
}
//1366x768
@media all and (min-width: 1290px) and (max-width: 1366px) {
    #rotator-cover-large .slider{
        max-height: 290px;
        overflow: hidden;
    }
}

//1440x900
//1600x900
@media all and (min-width: 1370px) and (max-width: 1440px), all and (min-width: 1530px) and (max-width: 1600px){
    #rotator-cover-large .slider{
        max-height: 420px;
        overflow: hidden;
    }
}

//1920x1080
@media all and (min-width: 1830px) and (max-width: 1920px) {
    #rotator-cover-large .slider{
        max-height: 600px;
        overflow: hidden;
    }
}
*/